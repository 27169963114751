import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    createAddDecoration,
    createUpdateDecoration,
    createUpdateDecorationRequest,
    createAddMatrixRow,
    createAddMatrixCol,
    createRemoveMatrixRow,
    createRemoveMatrixCol,
    createUpdateMatrix,
    createSetFixedChargeType,
    createAddListCharge,
    createUpdateListCharge,
    createAddListChargeToState,
} from '../../actions/admin_decoration_charges';

import { matrixIsEmpty, } from '../../admin-dec-charges-utils';

import TextArea from '../TextArea';
import TextInput from '../TextInput';
import Select from '../Select';

class AddAdminDecoration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            charge_types: [
                {key: 'run', value: 'Run charge'},
                {key: 'fixed', value: 'Fixed Charge'},
            ],
            loading: false,
            errors: null,
        };

        [
            'onChangeColsNum',
            'onChangeRowsNum',
        ].forEach(f => this[f] = this[f].bind(this));
    }

    componentDidMount() {
    }

    onChangeAttachment(attached_file) {
        this.props.updateDecoration({
            attached_file: attached_file.target ? attached_file.target.value : attached_file
        });
    }

    onChangeColsNum(cols_num, symbol) {
        const _cols_num = cols_num.target ? cols_num.target.value : cols_num;

        if (symbol == '+') {
            this.props.addMatrixCol(parseInt(_cols_num));
        } else if (symbol == '-') {
            this.props.removeMatrixCol(parseInt(_cols_num));
        }
    }

    onChangeRowsNum(_rows_num, symbol) {
        const rows_num = _rows_num.target ? _rows_num.target.value : _rows_num;

        if (symbol == '+') {
            this.props.addMatrixRow(parseInt(rows_num));
        } else if (symbol == '-') {
            this.props.removeMatrixRow(parseInt(rows_num));
        }
    }

    updateType(type) {
        if (this.props.type == type) { return; }

        const { decoration_data: { matrix }, } = this.props;
        const data = {
            type,
            remove_cols: true,
            data: [],
            children: (type=='list-item' || type=='list' ? [] : undefined),
        };

        if (!matrix.data ||
            (matrix.data && !matrix.data.length) ||
            (matrix.data && matrixIsEmpty(matrix)))
        {
            this.props.updateMatrix(data);
        }
        else if (window.confirm('By changing the type, your decoration table data might be deleted.')) {
            this.props.updateMatrix(data);
            // this.props.onAddChargeToState({ type });
        }
    }

    // Show On View (Cannot Edit)
    renderOnView() {
        const { decoration_data: { charge_desc }, } = this.props;

        return (
            <div className="medium-12 small-12 columns"
                style={{float: 'left', textAlign: 'left', fontSize: '0.6em', marginBottom: (charge_desc ? 20 : 1), }}
            >
                {(charge_desc ? charge_desc : '').split("\n").map((v,key) => {
                    return <div key={key}>{v}</div>;
                })}
            </div>
        );
    }

    // Check if Matrix has per Additional # Units
    hasMatrixPerAddUnit() {
        const { decoration_data: { matrix } } = this.props;
        if (matrix && matrix.cols && matrix.data && matrix.data.length > 1 && matrix.type == 'table') {
            if (matrix.data[matrix.data.length-1]['price_by_type'] == 'per_additional_unit') {
                return true;
            }
        }

        return false;
    }

    renderForm() {
        const { charge_types, error, } = this.state;
        const {
            decoration_id,
            decoration_data: { charge_name, charge_type, charge_desc, should_update, is_new, qty, unit, },
            cols_num,
            rows_num,
            type,
        } = this.props;

        const Label = (value, htmlFor_) => (<label htmlFor={htmlFor_ ? htmlFor_ + '-' + decoration_id : decoration_id} style={{textAlign: 'left'}}>{value}</label>);
        const minus_btn_style = {
            backgroundColor: '#02c0da',
            color: '#fff',
            paddingBottom: '0px',
            padding: '0',
            paddingRight: '2px',
            paddingLeft: '2px',
            fontSize: '1.1em',
            lineHeight: '0',
            fontWeight: '300',
            borderRadius: '3px 0px 0px 3px',
            textAlign: 'center',
            borderColor: '#02c0da',

            // ...(this.hasMatrixPerAddUnit() ? {
            //     background: '#DAE9EE',
            //     borderColor: '#DAE9EE',
            //     cursor: 'auto',
            // } : {background: '#02c0da', borderColor: '#02c0da', }),
        };

        return (
            <div style={{marginBottom: 20, }}>
                {error &&
                    <div><p style={{color: 'red', fontWeight: 'bold'}}>{error}</p></div>}
                <div className="columns large-3 medium-12 small-12">
                    {Label("Charge Name", "charge-name")}
                    <TextInput id={'charge-name-' + decoration_id}
                        field="charge_name"
                        value={charge_name}
                        required={true}
                        disabled={should_update == 0}
                        onChange={(e) => {
                            this.props.updateDecoration({charge_name: e.target.value});
                        }}
                        onBlur={e => this.props.updateDecorationRequest({charge_name: e.target.value})}
                    />
                </div>
                {charge_name ?
                    <div className={'columns large-'+(charge_type && charge_type == 'run' ? '2' : '3')+' medium-12 small-12'}>
                        {Label("Charge Type", "charge-type")}
                        <Select className="charge-type-select"
                            id={'charge-type-' + decoration_id}
                            style={{fontSize: 'initial'}}
                            searchable={true}
                            options={charge_types}
                            disabled={should_update == 0}
                            value={charge_type}
                            required={true}
                            onChange={(e) => {
                                const data = {charge_type: e.value};
                                if (e.value == 'fixed') {
                                    data['matrix_type'] = 'list';
                                } else {
                                    data['matrix_type'] = 'table';
                                }
                                if (is_new) {
                                    this.props.updateDecoration(data);
                                } else if (window.confirm('By changing Charge Type, your decoration table data might be removed.')) {
                                    this.props.updateDecoration(data);
                                    this.props.updateDecorationRequest({charge_type: e.value});
                                    if (e.value == 'fixed') {
                                        if (cols_num > 2) {
                                            this.props.removeMatrixCol(cols_num-1, cols_num);
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                : null}
                {charge_name && charge_type ?
                    <div className="columns large-3 medium-12 small-12" style={{paddingRight: 0, width: 'fit-content', marginRight: 20}}>
                        {Label("Type", "type")}
                        <div className="primary-toggle">
                            <a onClick={e => { e.preventDefault(); this.updateType('list'); }} className={('list' === type ? ' active-toggle' : ' inactive-toggle')}>
                                {charge_type=='run' ? 'Per Item' : 'List'}
                            </a>
                            <a onClick={e => { e.preventDefault(); this.updateType('table'); }} className={('table' === type ? ' active-toggle' : ' inactive-toggle')}>Table</a>
                        </div>
                    </div>
                : null}
                {charge_name && charge_type && type == 'table' ?
                    <div className={'columns large-3 medium-12 small-12'} style={{paddingLeft: 0, paddingRight: 0, }}>
                        <div className={'columns medium-6'} style={{paddingLeft: 0, paddingRight: 0, }}>
                            {Label("Rows", "rows-num")}
                            <div className="input-group">
                                <a className="input-group-label"
                                    style={{ ...minus_btn_style, }}
                                    onClick={() => {
                                        if (!this.hasMatrixPerAddUnit()) {
                                            this.props.removeMatrixRow(1, this.hasMatrixPerAddUnit() ? (rows_num-1) : rows_num);
                                        }
                                    }}
                                >-</a>
                                <TextInput className="input-group-field" id="rows-num" field="rows_num"
                                    value={rows_num} disabled={true}
                                    style={{textAlign: 'center', borderRadius: 0, }}
                                    required={true} onChange={this.onChangeRowsNum} />
                                <a className="input-group-label"
                                    style={{...minus_btn_style, borderRadius: '0 3px 3px 0', fontSize: '0.8em', }}
                                    onClick={() => {
                                        this.props.addMatrixRow(1, this.hasMatrixPerAddUnit() ? (rows_num-1) : rows_num);
                                    }}
                                >+</a>
                            </div>
                        </div>
                        {charge_type && charge_type != 'fixed' ?
                            <div className="columns medium-6" style={{paddingLeft: '0.5rem', paddingRight: 0, }}>
                                {Label("Breakdowns", "cols-num")}
                                <div className="input-group" style={{}}>
                                    <a className="input-group-label" style={minus_btn_style}
                                        disabled={should_update == 0}
                                        onClick={() => this.props.removeMatrixCol(1, cols_num)}
                                    >-</a>
                                    <TextInput className="input-group-field" id="cols-num" field="cols_num"
                                        value={cols_num} disabled={true}
                                        style={{textAlign: 'center', borderRadius: 0, }}
                                        required={true} onChange={this.onChangeColsNum} />
                                    <a className="input-group-label"
                                        disabled={should_update == 0}
                                        style={{...minus_btn_style, borderRadius: '0 3px 3px 0', fontSize: '0.8em'}}
                                        onClick={() => this.props.addMatrixCol(1, cols_num)}
                                    >+</a>
                                </div>
                            </div>
                        : null}
                    </div>
                : null}
                <div className="columns medium-12 small-12">
                    {Label("Description", "desc")}
                    <TextArea id={'charge-desc-' + decoration_id} field="charge_desc"
                        value={charge_desc}
                        required={false}
                        disabled={should_update == 0}
                        onChange={(e) => {
                            this.props.updateDecoration({charge_desc: e.target.value});
                        }}
                        onBlur={e => this.props.updateDecorationRequest({charge_desc: e.target.value})}
                    />
                </div>
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            // loading
        } else {
            if (this.props.can_edit) {
                return this.renderForm();
            } else {
                return this.renderOnView();
            }
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateDecoration: (data) => {
        const {decoration_id, decorator_id, decoration_data} = ownProps;
        dispatch(createUpdateDecoration(decoration_id, data));

        if (data.charge_type && decoration_data.is_new && decoration_data.charge_name) {
            return dispatch(createAddDecoration({...decoration_data, ...data}));
        }
    },
    updateDecorationRequest: (data) => {
        const {decoration_id, decorator_id, decoration_data} = ownProps;

        if (decoration_data.is_new && decoration_data.charge_name && decoration_data.charge_type) {
            return dispatch(createAddDecoration({...decoration_data, ...data}));
        } else if (!decoration_data.is_new) {
            return dispatch(createUpdateDecorationRequest({decoration_id: decoration_id, decorator_id: decorator_id, ...data}));
        }
    },
    addMatrixRow: (value, last_idx) => {
        if (ownProps.decoration_data.is_new) {
            if (!ownProps.decoration_data.charge_type) {
                return false;
            }
        }

        return dispatch(createAddMatrixRow(ownProps.decoration_id, value, last_idx));
    },
    addMatrixCol: (value, last_idx) => {
        if (ownProps.decoration_data.is_new) {
            if (!ownProps.decoration_data.charge_type) {
                return false;
            }
        }

        return dispatch(createAddMatrixCol(ownProps.decoration_id, value, last_idx));
    },
    removeMatrixRow: (value, last_idx) => {
        if (last_idx>1) {
            if (ownProps.decoration_data.is_new) {
                if (!ownProps.decoration_data.charge_type) {
                    return false;
                }
            }

            return dispatch(createRemoveMatrixRow(ownProps.decoration_id, value, last_idx));
        }
    },
    removeMatrixCol: (value, last_idx) => {
        if (last_idx>0) {
            if (ownProps.decoration_data.is_new) {
                if (!ownProps.decoration_data.charge_type) {
                    return false;
                }
            }

            dispatch(createRemoveMatrixCol(ownProps.decoration_id, value, last_idx));
        }
    },
    updateMatrix: (value) => dispatch(createUpdateMatrix(ownProps.decoration_id, value)),
    setFixedType: (value) => dispatch(createSetFixedChargeType(ownProps.decoration_id, value)),
    onAddChargeToState: (data) => dispatch(createAddListChargeToState(ownProps.decoration_id, data)),
    onAddCharge: (data) => {
        return dispatch(createAddListCharge(ownProps.decoration_id, data));
    },
    onUpdateCharge: (data) => {
        return dispatch(createUpdateListCharge(ownProps.decoration_id, data));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAdminDecoration);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    createAddDecoration,
    createAddMatrixRow,
    createUpdateDecoration,
    createUpdateDecorationRequest,
    createUpdateMatrix,
    createUpdateMatrixList,
    createUpdateMatrixColsDef,
    createUpdateMatrixRowsDef,
    createUpdateMatrixCell,
    createAddListCharge,
    createUpdateListCharge,
    createStateUpdateListCharge,
    createAddListChargeToState,
    createRemoveFixMatrixRow,
    createRemoveListChargeItem,
    createStateUpdateListCharges,
} from '../../actions/admin_decoration_charges';
import { generateCharge, convertToFloat } from '../../admin-dec-charges-utils';

import { Table, Footer } from '../TableLibrary';
import { ChargeListItem } from './helpers';

export class AdminDecorationChargesMatrix extends Component {
    constructor(props) {
        super(props);

        this.onListItemChange = this.onListItemChange.bind(this);
        this.onListItemChangeRequest = this.onListItemChangeRequest.bind(this);
    }

    onListItemChange(data) {
        if (data.unit !== null && data.unit !== undefined) {
            this.props.updateDecoration({ units: data.unit, });
            this.props.onUpdateChargesState({ unit: data.unit, });
        } else if (data.qty !== null && data.qty !== undefined) {
            this.props.updateDecoration({ qty: data.qty });
            this.props.onUpdateChargesState({ qty: data.qty, });
        }
    }

    onListItemChangeRequest(data) {
        if (data.unit !== null && data.unit !== undefined) {
            this.props.updateDecorationRequest({ units: data.unit, });
            this.props.onUpdateChargesState({ unit: data.unit, });
        } else if (data.qty !== null && data.qty !== undefined) {
            this.props.updateDecorationRequest({ qty: data.qty });
            this.props.onUpdateChargesState({ qty: data.qty, });
        }
    }

    // Check if Matrix has per Additional # Units
    hasMatrixPerAddUnit() {
        const { decoration_data: { matrix } } = this.props;
        if (matrix && matrix.cols && matrix.data && matrix.data.length > 1 && matrix.type == 'table') {
            if (matrix.data[matrix.data.length-1]['price_by_type'] == 'per_additional_unit') {
                return true;
            }
        }

        return false;
    }

    renderList() {
        const {
            decoration_data: { matrix, charge_type, units, qty, },
            updateMatrix,
            onAddCharge,
            onUpdateCharge,
            onRemoveListChargeItemRow,
            can_edit,
        } = this.props;

        return (
            <div>
                {charge_type == 'fixed' ?
                    <ChargeListItem
                        charge_data={{unit: units, qty: qty }}
                        charge_type={charge_type}
                        onChange={this.onListItemChange}
                        onUpdateRequest={this.onListItemChangeRequest}
                        onDelete={() => updateMatrix({ type: 'list', all: true })}
                        is_parent={true}
                        is_decoration={true}
                        can_edit={can_edit}
                    />
                : null}
                {matrix.data.map((v, i) => {
                    return (
                        <ChargeListItem
                            key={v.row_num + '_' + v.cell_value + '_' + i}
                            charge_data={v}
                            charge_type={charge_type}
                            idx={i}
                            onChange={onUpdateCharge}
                            onDelete={onRemoveListChargeItemRow}
                            is_child={true}
                            parent_data={{unit: units, qty: qty }}
                            can_edit={can_edit}
                        />
                    );
                })}
                {can_edit ?
                    <div className="columns medium-12 small-12 large-12" style={{textAlign: 'left',}}>
                        <a style={{fontSize: '1rem', }}
                            onClick={(e) => {
                                e.preventDefault();
                                onAddCharge({
                                    cell_value: convertToFloat(1),
                                    unit: '',
                                    qty: 1,
                                    label: '',
                                });
                            }}
                        >+ Add Charge</a>
                    </div>
                : null}
            </div>
        );
    }

    renderTable() {
        const {
            decoration_data: { matrix, charge_type, },
            updateMatrixCell,
            updateMatrixList,
            updateMatrixColsDef,
            onRemoveMatrixRow,
            can_edit,
        } = this.props;

        return (
            <div>
                <div className={'small-1'+(can_edit ? '1' : '2')+' columns'} style={{paddingLeft: 0, float: 'left', }}>
                    <div id="my-table">
                        {matrix.cols && matrix.cols.length > 0 && matrix.data.length > 0 ? <Table
                            colDef={matrix.cols}
                            list={matrix.data}
                            isInputEditable={can_edit}
                            isNumberMatrix={true}
                            isEditable={true}
                            fixedRowCount={1}
                            fixedColumnCount={1}
                            onCellChange={updateMatrixCell}
                            updateList={updateMatrixList}
                            onColDefChange={(newValue, colObj) => updateMatrixColsDef({newValue, colObj})}
                            fixedColLabel={charge_type == 'fixed' ? 'Cost' : false}
                            // fixedHeight={matrix.data.length > 7 ? 400 : null}
                            onRemoveMatrixRow={(idx) => {
                                if (matrix.data && matrix.data.length > 1) {
                                    onRemoveMatrixRow(parseInt(idx));
                                }
                            }}
                            shouldDeleteRow={() => {
                                if (matrix.data && matrix.data.length > 1) {
                                    return true;
                                }
                                return false;
                            }}
                            hoverOnX={true}
                            decoration_id={this.props.decoration_id}
                        /> : null}
                    </div>

                    {can_edit && charge_type != 'fixed' && matrix.data && matrix.data.length > 1 && !this.hasMatrixPerAddUnit() &&
                        <div style={{backgroundColor: 'rgb(242, 242, 242)', textAlign: 'left', fontSize: 18, fontWeight: 'bold', padding: 10, }}>
                            <a onClick={() => {
                                this.props.addMatrixRow(1, {price_by_type: 'per_additional_unit', });
                            }}>+ Greater than {matrix.data[matrix.data.length-1]['-']}</a>
                        </div>
                    }
                </div>
            </div>
        );
    }

    render() {
        if (this.props.type == 'list') {
            return this.renderList();
        }

        return this.renderTable();
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = (dispatch, ownProps) => ({
    updateMatrixColsDef: (value) => {
        if (ownProps.decoration_data.is_new) {return;}
        if (value.colObj.idx == 0) {
            dispatch(createUpdateDecorationRequest({
                decoration_id: ownProps.decoration_id,
                decorator_id: ownProps.decorator_id,
                units: value.newValue
            }));
            return dispatch(createUpdateMatrix(ownProps.decoration_id, {units: value.newValue}));
        }
        return dispatch(createUpdateMatrixColsDef(ownProps.decoration_id, value));
    },
    updateMatrixList: (value) => {
        if (ownProps.decoration_data.is_new) {return;}

        return dispatch(createUpdateMatrixList(ownProps.decoration_id, value));
    },
    updateMatrixCell: (value) => {
        if (ownProps.decoration_data.is_new) {return;}

        if (value.key == '-') {
            return dispatch(createUpdateMatrixRowsDef(ownProps.decoration_id, value));
        } else {
            const colData = ownProps.decoration_data.matrix.cols.filter((val) => val.key == value.key)[0];
            return dispatch(createUpdateMatrixCell(ownProps.decoration_id, value, colData));
        }
    },

    updateDecoration: (data) => {
        const {decoration_id, decorator_id, decoration_data} = ownProps;
        dispatch(createUpdateDecoration(decoration_id, data));
    },
    updateDecorationRequest: (data) => {
        const {decoration_id, decorator_id, decoration_data} = ownProps;

        if (decoration_data.is_new && decoration_data.charge_name && decoration_data.charge_type) {
            return dispatch(createAddDecoration({...decoration_data, ...data}));
        } else if (!decoration_data.is_new) {
            return dispatch(createUpdateDecorationRequest({decoration_id: decoration_id, decorator_id: decorator_id, ...data}));
        }
    },
    onAddChargeState: () => {
        const charge = generateCharge(ownProps.decoration_data.matrix.data.length, ownProps.decoration_data.units);
        return dispatch(createAddListChargeToState(ownProps.decoration_id, charge));
    },
    onAddCharge: (data) => {
        return dispatch(createAddListCharge(ownProps.decoration_id, data));
    },
    onUpdateCharge: (value) => {
        const {decoration_data: { matrix, }} = ownProps;
        let charge = matrix.data.filter(v => v.charge_id == value.charge_id)[0];
        if (charge && charge.charge_id && charge.charge_id.indexOf('temp_charge_') != -1)
        {
            return dispatch(createAddListCharge(ownProps.decoration_id, {...charge, ...value}));
        }
        return dispatch(createUpdateListCharge(ownProps.decoration_id, value));
    },
    onUpdateChargeState: (value) => {
        return dispatch(createStateUpdateListCharge(ownProps.decoration_id, value));
    },
    onUpdateChargesState: (value) => {
        return dispatch(createStateUpdateListCharges(ownProps.decoration_id, value));
    },
    onRemoveMatrixRow: (value) => {
        const {decoration_data: { matrix, }} = ownProps;
        if (matrix.data.length > 1) {
            dispatch(createRemoveFixMatrixRow(ownProps.decoration_id, value));
        }
    },
    onRemoveListChargeItemRow: (value) => dispatch(createRemoveListChargeItem(ownProps.decoration_id, value)),
    updateMatrix: (value) => dispatch(createUpdateMatrix(ownProps.decoration_id, value)),
    addMatrixRow: (value, data) => {
        if (ownProps.decoration_data.is_new) {
            if (!ownProps.decoration_data.charge_type) {
                return false;
            }
        }

        return dispatch(createAddMatrixRow(ownProps.decoration_id, value, data));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDecorationChargesMatrix);

import React, { Component } from "react";

export class InputCell extends Component {
  constructor(props) {
    super(props);
    this.state = { inputValue: this.props.children };

    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
  }

  onBlur = () => {
    this.props.onCellBlur(this.state.inputValue);

    if (this.props.checkValOnUpdate) {
      if (this.state.inputValue !== this.props.children) {
        this.setState({ inputValue: this.props.children });
      }
    }
  };

  onChangeInputValue = event => {
    if (this.props.checkNumber) {
      if (isNaN(event.target.value)) {
        return;
      }
    }
    this.setState({ inputValue: event.target.value });
  };

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      this.setState({ inputValue: this.props.children });
    }
  }

  handleOnKeyDown(e) {
    if (e.keyCode == 9) {
      this.props.scrollToColumn(this.props.isLastCol ? 1 : this.props.columnIndex+1);
      // this.props.scrollToRow(this.props.isLastCol ? this.props.rowIndex+2 : this.props.rowIndex)
    }
  }

  renderInputLabel() {
    const { inputLabel, inputLabelPrefix, inputLabelPostfix, } = this.props;

    if (inputLabelPrefix && inputLabelPostfix) {
      return inputLabelPrefix + inputLabel + inputLabelPostfix;
    }
    else if (inputLabelPrefix) {
      return inputLabelPrefix + inputLabel;
    }
    else if (inputLabelPostfix) {
      return inputLabel + inputLabelPostfix;
    }

    return inputLabel;
  }

  render() {
    const {
      cellValue,
      onCellBlur,
      idx,
      isHeader,
      inputLabel,
      inputLabelPrefix,
      inputLabelPostfix,
      noInputLabel,
      checkNumber,
      inputStyles={},
      fixedColLabel,
      isInputEditable,
      hasDel,
      rowObj,
      ...props
    } = this.props;

    const isPerAdditional = rowObj && rowObj.price_by_type=='per_additional_unit';

    if (fixedColLabel !== false && fixedColLabel !== null && fixedColLabel !== undefined) {
      return (
        <div className="two columns end"
          style={{
            display: 'inline-block', paddingRight: '5px', paddingLeft: '10px', overflow: 'hidden',
            whiteSpace: 'nowrap', width: '100%', textAlign: 'left', marginTop: '10px',
          }}
        >
          <label style={{ fontSize: '1rem', overflow: 'auto', whiteSpace: 'nowrap', fontWeight: 'bold', }}>
            {fixedColLabel}
          </label>
        </div>
      );
    }

    if (isHeader) {
      if (isInputEditable === false) {
        return (
          <div style={{overflow: 'hidden'}}>
            <div className="three columns"
              style={{
                display: 'inline-block', paddingRight: '5px', paddingLeft: '10px', overflow: 'auto',
                whiteSpace: 'nowrap', width: !noInputLabel ? '50%' : '100%', textAlign: !noInputLabel ? 'right' : 'center', marginTop: '10px',
              }}
            >
              <label style={{ fontSize: '1rem', }}>
                {this.state.inputValue}
              </label>
            </div>
            {!noInputLabel ?
              <div className="two columns end"
                style={{
                  display: 'inline-block', paddingRight: '10px', paddingLeft: '2px', overflow: 'auto',
                  whiteSpace: 'nowrap', width: '50%', textAlign: 'left', marginTop: '10px',
                }}
              >
                <label style={{ fontSize: '1rem', }}>
                  {this.renderInputLabel()}
                </label>
              </div>
            : null}
          </div>
        );
      }

      return (
        <div>
          <div className="three columns" style={{ display: 'inline-block', paddingLeft: '10px', paddingRight: '3px', width: noInputLabel ? '100%' : '50%' }}>
            <input type={checkNumber ? 'number' : 'text'} className="" id={'coldef_' + idx} placeholder={this.props.placeholder}
              value={this.state.inputValue}
              style={{ marginTop: '5px', textAlign: !noInputLabel ? 'right' : 'center', ...inputStyles }}
              onChange={this.onChangeInputValue}
              onBlur={this.onBlur}
              onFocus={(e) => e.target.select()}
              // onKeyDown={this.handleOnKeyDown}
            />
          </div>
          {!noInputLabel ?
            <div className="two columns end"
              style={{
                display: 'inline-block', paddingRight: '10px', paddingLeft: '2px', overflow: 'hidden',
                whiteSpace: 'nowrap', width: '50%', textAlign: 'left', marginTop: '10px',
              }}
            >
              <label style={{ fontSize: '1rem', overflow: 'auto', }}>
                {this.renderInputLabel()}
              </label>
            </div>
          : null}
        </div>
      );
    }

    let noInputLabelStyles = {};
    if (noInputLabel) {
      noInputLabelStyles = {
        position: 'inherit',
        width: '85%',
        left: 12,
        top: 1,
        marginBottom: 10,
      };
    }

    if (isInputEditable === false) {
      return (
        <span style={{ fontSize: '1rem', paddingLeft: 20, marginTop: isPerAdditional ? 0 : 15, float: 'left', textAlign: 'left', }}>
          <span>{isPerAdditional && <span style={{fontSize: '0.875rem'}}><strong>Each <br />Additional</strong></span>} {'  ' + this.state.inputValue}</span>
          {!noInputLabel ?
            <div style={{ marginBottom: 6, verticalAlign: 'middle', fontSize: '1rem', paddingLeft: '80px', }}>
              <span>{inputLabel}</span>
            </div>
          : null}
        </span>
      );
    }

    if (hasDel === true) {
      return (
        <div className="row" style={{marginTop: -5}}>
          <div className="input-group" style={{height: 33, marginTop: 2, display: 'inline-block', }}>
            {this.props.shouldDeleteRow()===true && <a className="input-group-label"
              style={{ color: '#5CA3B6', padding: 0, fontSize: '0.6em', lineHeight: 0, textAlign: 'center',
                display: 'inline-block', width: '15%', height: 33, paddingBottom: 0, paddingTop: 15,
                background: 'inherit', border: 0, marginLeft: isPerAdditional ? -45 : -15, visibility: (this.props.isHovering===true ? 'visible' : 'hidden') }}
              onClick={this.props.onDelete}
            >&times;</a>}
            {isPerAdditional && <label style={{
                width: '10%',
                display: 'inline-block',
                background: 'none',
                border: 0,
                overflow: 'visible',
                marginRight: 20,
                lineHeight: 'normal',
                paddingRight: 25,
              }}>Each <br />Additional</label>}
            <input className="input-group-field"
              id={'rowdef_' + idx}
              value={this.state.inputValue}
              field={'rowdef_' + idx}
              type={checkNumber ? 'number' : 'text'}
              style={{width: isPerAdditional ? '40%' : '80%', borderRadius: 3, display: 'inherit', height: 'inherit', ...(isPerAdditional ? {marginLeft: 30, marginRight: -20} : {})}}
              onChange={this.onChangeInputValue}
              onBlur={this.onBlur}
              onFocus={(e) => e.target.select()}
              disabled={isInputEditable === false} />
          </div>
        </div>
      );
    }

    return (
      <div className="row" style={{marginTop: -5}}>
        <div style={{ height: 33, left: 3, position: 'absolute', top: 1, width: '100%', display: 'inline-block', ...noInputLabelStyles }}>
          <input type={checkNumber ? 'number' : 'text'}
            // className="c-editable-cell--input "
            id={'rowdef_' + idx}
            value={this.state.inputValue}
            style={{ marginTop: 2, height: 'inherit', textAlign: 'right', background: 'initial', ...inputStyles, }}
            onChange={this.onChangeInputValue}
            onBlur={this.onBlur}
            onFocus={(e) => e.target.select()}
            disabled={isInputEditable === false}
          />
        </div>
        {!noInputLabel ?
          <div style={{ marginBottom: 6, verticalAlign: 'middle', fontSize: '1rem', paddingLeft: '80px', }}>
            <span>{inputLabel}</span>
          </div>
        : null}
      </div>
    );
  }
}
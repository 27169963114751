import React, { Component, Fragment } from "react";
import { MultiGrid } from "react-virtualized";
import AutoSizer from "react-virtualized/dist/es/AutoSizer";
// import "react-virtualized/styles.css";

import {
  Cell,
  EditableCell,
  CheckboxCell,
  HeaderCell,
  SubHeaderCell,
  InputCell
} from "../cell";
import TabKeyStepper from "../keyStepper/TabKeyStepper";
import { sorter } from "../utils/sorter";
import PropTypes from "prop-types";
// import './table.css';

/**
 * @file Table is a React Component wrapper around {@link https://github.com/bvaughn/react-virtualized} React Virtualized MultiGrid.}
 *
 * @module Table
 * @extends Component
 */
export class Table extends Component {
  static defaultProps = {
    isBoxShadow: true,
    rowHeight: 50,
    headerHeight: 50,
    showHeader: true,
  };

  state = {
    hoveredColumnIndex: null,
    hoveredRowIndex: null,
    list: this.props.list,
    colDef: this.props.colDef,
    isAccending: false,
    activeColumn: null,
    checkboxStates: {},

    scrollToColumn: 0,
    scrollToRow: 0,
  };

  _grid = null;

  constructor(props) {
    super(props);

    this._onScrollToColumnChange = this._onScrollToColumnChange.bind(this);
    this._onScrollToRowChange = this._onScrollToRowChange.bind(this);
  }

  getHeaderHeight() {
    if (this.props.showHeader) {
      return this.props.headerHeight;
    }
    return 0;
  }

  // Responsible for rendering a single cell, given its row and column index.
  cellRenderer = ({ style, columnIndex, rowIndex, key }) => {
    const {
      list,
      colDef,
      isSortOn,
      isZebra,
      hoverOnX,
      hoverOnY,
      isBoxShadow,
      onLabelClick,
      showHeader,
    } = this.props;

    const rowObj = list[rowIndex - 1];
    const columnKey = colDef[columnIndex].key;
    const colStyle = colDef[columnIndex].style ? colDef[columnIndex].style : {};
    const disableHeader = colDef[columnIndex].disableHeader ? colDef[columnIndex].disableHeader : true;

    //  HEADER CELL
    if (rowIndex === 0 && columnKey !== "checkbox") {

      // CUSTOM COMPONENT HEADER CELL
      if (colDef[columnIndex].input_header) {
        const componentData = colDef[columnIndex].input_header;

        return (
          <Cell
            key={key}
            style={{...style, textAlign: 'center', backgroundColor: '#f2f2f2', fontWeight: 'bold', color: '#5CA3B6', alignItems: 'baseline',
              ...(componentData.parentStyles ? componentData.parentStyles : {}),

              borderRight: columnIndex==0 ? '2px solid rgb(204, 213, 218)' : (columnIndex==colDef.length-1 ? '2px solid rgb(204, 213, 218)' : '1px solid rgb(204, 213, 218)'),
            }}
            className="c-table--box-shadow-th row"
            onMouseOver={() =>
              this.onMouseOverCell(hoverOnY, hoverOnX, columnIndex, rowIndex)
            }
          >
            <InputCell
              isHeader={componentData.isHeader}
              idx={colDef[columnIndex][componentData.idxKey]}
              inputLabel={componentData.inputLabel ? componentData.inputLabel : (colDef[columnIndex][componentData.inputLabelKey] ? colDef[columnIndex][componentData.inputLabelKey] : '')}
              inputLabelPrefix={(columnIndex == colDef.length-1) ? componentData.inputLabelLastPrefix : componentData.inputLabelPrefix}
              onCellBlur={newValue => this.onEditColDef(newValue, colDef[columnIndex])}
              noInputLabel={componentData.noInputLabel}
              checkNumber={componentData.checkNumber}
              placeholder={componentData.placeholder}
              checkValOnUpdate={componentData.checkValOnUpdate}
              inputStyles={componentData.inputStyles}
              fixedColLabel={columnIndex > 0 ? this.props.fixedColLabel : false}
              isInputEditable={this.props.isInputEditable}
              columnIndex={columnIndex}
              rowIndex={rowIndex}
              isLastCol={columnIndex === colDef.length-1}
              isLastRow={rowIndex === list.length-1}
              scrollToColumn={this._onScrollToColumnChange}
              scrollToRow={this._onScrollToRowChange}
            >
              {componentData.value ? componentData.value : colDef[columnIndex][componentData.valueKey]}
            </InputCell>
          </Cell>
        );
      }

      if (colDef[columnIndex].header_component) {
        const Component = colDef[columnIndex].header_component;
        return (
          <Cell
            key={key}
            style={{...style, textAlign: 'center', backgroundColor: '#f2f2f2', fontWeight: 'bold', color: '#5CA3B6', alignItems: 'baseline'}}
            className="c-table--box-shadow-th row"
            onMouseOver={() =>
              this.onMouseOverCell(hoverOnY, hoverOnX, columnIndex, rowIndex)
            }
          >
            <Component
              rowData={rowObj}
              columnIndex={columnIndex}
              rowIndex={rowIndex}
              isLastCol={columnIndex === colDef.length-1}
              isLastRow={rowIndex === list.length-1}
              scrollToColumn={this._onScrollToColumnChange}
              scrollToRow={this._onScrollToRowChange}
              columnData={colDef[columnIndex]}
              onColBlur={newValue => this.onEditColDef(newValue, colDef[columnIndex])}
              onColChange={newValue => this.onEditColDef(newValue, colDef[columnIndex])}
              fixedColLabel={columnIndex > 0 ? this.props.fixedColLabel : false}
            />
          </Cell>
        );
      }

      const columnLabel = colDef[columnIndex].label;
      const isActiveSortCell = this.state.activeColumn === columnKey;
      return (
        <HeaderCell
          key={key}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          isLastCol={columnIndex === colDef.length-1}
          isLastRow={rowIndex === list.length-1}
          scrollToColumn={this._onScrollToColumnChange}
          scrollToRow={this._onScrollToRowChange}
          style={style}
          isBoxShadow={isBoxShadow}
          headerLabel={columnLabel}
          isSortOn={isSortOn}
          onSort={() => this.sortColumn(columnKey)}
          onLabelClick={
            onLabelClick ? () => onLabelClick(colDef[columnIndex]) : false
          }
          isInputEditable={this.props.isInputEditable}
          isActiveSortCell={isActiveSortCell}
          isAccendingSort={this.state.isAccending && isActiveSortCell}
        />
      );
    }

    // SUBHEADER CELL
    if (rowObj && rowObj.subheader) {
      return (
        <SubHeaderCell
          key={key}
          style={style}
          isFirstCell={columnIndex === 0}
          subHeaderTitle={rowObj.subheader}
        />
      );
    }

    // Only get the classes when desired effect is activated
    const rowClass = isZebra ? this.getRowClass(rowIndex) : "";
    const hoverCellClass =
      hoverOnY || hoverOnX ? this.getHoverCellClass(rowIndex, columnIndex) : "";

    // CHECKBOX CELL
    if (columnKey === "checkbox") {
      const isHeader = rowIndex === 0;
      return (
        <CheckboxCell
          key={key}
          checkboxKey={key}
          style={style}
          className={`${rowClass} ${hoverCellClass}`}
          isHeader={isHeader}
          isBoxShadow={isBoxShadow}
          onHandleCheckbox={isChecked =>
            this.onHandleCheckbox(isChecked, isHeader ? "ALL" : rowObj)
          }
          isChecked={
            rowObj &&
            !!this.state.checkboxStates[rowObj.id] &&
            this.state.checkboxStates[rowObj.id].isChecked
          }
          onMouseOver={() =>
            this.onMouseOverCell(hoverOnY, hoverOnX, columnIndex, rowIndex)
          }
        />
      );
    }

    // CUSTOM COMPONENT CELL
    if (colDef[columnIndex].component) {
      const Component = colDef[columnIndex].component;
      return (
        <Cell
          key={key}
          style={style}
          className={`${rowClass} ${hoverCellClass}`}
          onMouseOver={() =>
            this.onMouseOverCell(hoverOnY, hoverOnX, columnIndex, rowIndex)
          }
        >
          <Component
            rowData={rowObj}
            isInputEditable={this.props.isInputEditable}
            columnKey={columnKey}
            onCellBlur={newValue => this.onEditCell(newValue, rowObj, columnKey)}
            onCellChange={newValue => this.onEditCell(newValue, rowObj, columnKey)}
            onMouseOver={() =>
              this.onMouseOverCell(hoverOnY, hoverOnX, columnIndex, rowIndex)
            }
            isHovering={columnIndex === this.state.hoveredColumnIndex ||
              rowIndex === this.state.hoveredRowIndex}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            isLastCol={columnIndex === colDef.length-1}
            isLastRow={rowIndex === list.length-1}
            scrollToColumn={this._onScrollToColumnChange}
            scrollToRow={this._onScrollToRowChange}
          />
        </Cell>
      );
    }

    if (colDef[columnIndex].input_component) {
      const componentData = colDef[columnIndex].input_component;
      return (
        <Cell
          key={key}
          style={{
            ...style,
            // marginTop: -5,
            borderBottom: '1px solid rgb(204, 213, 218)',
            borderRight: '2px solid rgb(204, 213, 218)',
          }}
          className={`${rowClass} ${hoverCellClass} c-header-bg`}
          onMouseOver={() =>
            this.onMouseOverCell(hoverOnY, hoverOnX, columnIndex, rowIndex)
          }
        >
          <InputCell
            isHeader={componentData.isHeader}
            idx={componentData.idx ? componentData.idx : rowObj[componentData.idxKey]}
            inputLabel={componentData.inputLabel ? componentData.inputLabel : rowObj[componentData.inputLabelKey]}
            onCellBlur={newValue => this.onEditCell(newValue, rowObj, columnKey)}
            checkNumber={componentData.checkNumber}
            checkValOnUpdate={componentData.checkValOnUpdate}
            inputStyles={componentData.inputStyles}
            noInputLabel={componentData.noInputLabel}
            isInputEditable={this.props.isInputEditable}
            hasDel={componentData.hasDel}
            onDelete={(e) => { e.preventDefault(); this.props.onRemoveMatrixRow(rowIndex); }}
            hoverCellClass={hoverCellClass}
            isHovering={columnIndex === this.state.hoveredColumnIndex || rowIndex === this.state.hoveredRowIndex}
            shouldDeleteRow={this.props.shouldDeleteRow}
            rowObj={rowObj}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            isLastCol={columnIndex === colDef.length-1}
            isLastRow={rowIndex === list.length-1}
            scrollToColumn={this._onScrollToColumnChange}
            scrollToRow={this._onScrollToRowChange}
          >
            {componentData.value ? componentData.value : rowObj[componentData.valueKey]}
          </InputCell>
        </Cell>
      );
    }

    // EDITABLE CELL
    if (this.props.isEditable && colDef[columnIndex]['editable'] !== false) {
      return (
        <EditableCell
          key={key}
          style={style}
          className={`${rowClass} ${hoverCellClass}`}
          inputClass={isZebra ? "c-table--input-zebra" : ""}
          onCellBlur={newValue => this.onEditCell(newValue, rowObj, columnKey)}
          isInputEditable={this.props.isInputEditable}
          isNumberMatrix={this.props.isNumberMatrix}
          onMouseOver={() =>
            this.onMouseOverCell(hoverOnY, hoverOnX, columnIndex, rowIndex)
          }
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          isLastCol={columnIndex === colDef.length-1}
          // isLastRow={rowIndex === list.length-1}
          scrollToColumn={this._onScrollToColumnChange}
          scrollToRow={this._onScrollToRowChange}
          columnCount={colDef.length}
          rowCount={list.length}
          onClick={() => {
            this._selectCell({
              scrollToColumn: columnIndex,
              scrollToRow: rowIndex,
            });
          }}
          decoration_id={this.props.decoration_id}
        >
          {rowObj[columnKey]}
        </EditableCell>
      );
    }

    // REGULAR CELL
    return (
      <Cell
        key={key}
        style={style}
        className={`${rowClass} ${hoverCellClass} c-cell-border`}
        onMouseOver={() =>
          this.onMouseOverCell(hoverOnY, hoverOnX, columnIndex, rowIndex)
        }
      >
        {rowObj[columnKey]}
      </Cell>
    );
  };

  /**
   *
   * @param {boolean} isChecked
   * @param {object|string} checkedRow
   */
  onHandleCheckbox = (isChecked, checkedRow) => {
    // SET ALL CHECKED OR UNCHECKED
    if (checkedRow === "ALL") {
      const allRows = this.props.list.reduce(
        (acc, row, currentIndex, array) => {
          acc[row.id] = { id: row.id, isChecked: isChecked };
          return acc;
        },
        {}
      );
      this.setState({ checkboxStates: allRows });
    }

    // CHECK THE SELECTED CELL
    this.setState(({ checkboxStates }) => ({
      checkboxStates: {
        ...checkboxStates,
        [checkedRow.id]: { id: checkedRow.id, isChecked: isChecked }
      }
    }));
  };

  /**
   * Sets hover effect vertical or horizontal if activated
   *
   * @param {boolean} hoverOnY - enables vertical hover effect
   * @param {boolean} hoverOnX - enables horizontal hover effect
   * @param {number} columnIndex - determinds what column is active
   * @param {number} rowIndex - determinds what row is active
   */
  onMouseOverCell = (hoverOnY, hoverOnX, columnIndex, rowIndex) => {
    if (hoverOnY || hoverOnX) {
      this.setState({
        hoveredColumnIndex: hoverOnY ? columnIndex : null,
        hoveredRowIndex: hoverOnX ? rowIndex : null
      });
    }
  };

  /**
   *
   * @param {number} rowIndex - Get the hover class for the row when match
   * @param {number} columnIndex Get the hover class for the column when match
   */
  getHoverCellClass = (rowIndex, columnIndex) => {
    const hoverClass =
      columnIndex === this.state.hoveredColumnIndex ||
      rowIndex === this.state.hoveredRowIndex
        ? "c-table--td-hover-cell"
        : "";
    return hoverClass;
  };

  /**
   *
   * @param {string} newValue
   * @param {object} rowObj
   * @param {string} key
   */
  onEditCell = (newValue, rowObj, key) => {
    this.props.onCellChange({ rowObj, key, newValue });
  };

  onEditColDef = (newValue, colObj) => {
    this.props.onColDefChange(newValue, colObj);
  };

  /**
   * Used to get zebra stripes class
   *
   * @param {number} index
   */
  getRowClass = index => {
    return index % 2 === 0 ? "c-table--row-even" : "c-table--row-odd";
  };

  /**
   *  When the responsive window width is greater
   * then the specefied total in colDef
   * we will show the width of the total colDef
   *
   *  eg: window width = 800px
   *     colDef width = 400px
   *     (We show colDef width)
   *
   * @param {array} colDef
   * @param {number} windowWidth
   * @return {number}
   */
  calculateGridWidth = (colDef, windowWidth) => {
    const totalColDefWidth = colDef.reduce(
      (acc, val) => (acc = acc + val.size),
      0
    );
    return totalColDefWidth < windowWidth ? totalColDefWidth : windowWidth;
  };

  /**
   *
   * @param {array} list
   * @param {number} rowHeight
   * @return {number}
   */
  calculateGridHeight = (list, rowHeight) => {
    const numberOfRows = list.length;
    return rowHeight * (numberOfRows+(this.props.showHeader ? 1 : 0));
  };

  _onScrollToRowCol = (row, col, action1, action2) => {
    this.setState({
      scrollToColumn: col,
      scrollToRow: row,
    }, () => {
      if (action1 == 'Tab' && action2 == 'shift') {
        //
      }
    });
  }

  _onScrollToColumnChange (value) {
    this.setState({
      scrollToColumn: value,
    });
  }

  _onScrollToRowChange (value) {
    this.setState({
      scrollToRow: value,
    });
  }

  /**
   *
   * @param {string} column
   */
  sortColumn(column) {
    const isFirstTime = this.state.activeColumn !== column;
    const { isAccending } = this.state;
    const { list, updateList } = this.props;
    // First time sorting should be accending ↑
    if (isFirstTime) {
      let updatedList = sorter(list, column, true);
      if (updateList) { updateList(updatedList); }

      this.setState(
        ({ isAccending }) => ({
          // list: updatedList,
          isAccending: true,
          activeColumn: column
        }),
        () => this._grid.forceUpdateGrids()
      );
    } else {
      // Sort based on the oposite state of the current direction
      let updatedList = sorter(list, column, !isAccending);
      if (updateList) { updateList(updatedList); }
      this.setState(
        ({ isAccending }) => ({
          // list: updatedList,
          isAccending: !isAccending,
          activeColumn: column
        }),
        () => this._grid.forceUpdateGrids()
      );
    }
  }

  _selectCell = ({scrollToColumn, scrollToRow}) => {
    this.setState({scrollToColumn, scrollToRow});
  };

  renderTabbed() {
    const {
      colDef,
      fixedRowCount,
      fixedColumnCount,
      fixedHeight,
      fixedWidth,
      style,
      rowHeight,
      isBoxShadow,
      footer,
      list,
    } = this.props;

    const mode = 'cells';
    const isClickable = true;

    return (
      <TabKeyStepper
        columnCount={colDef.length}
        rowCount={list.length + 1}
        scrollToRow={this.state.scrollToRow}
        scrollToColumn={this.state.scrollToColumn}
        mode={mode}
        onScrollToChange={isClickable ? this._selectCell : undefined}
      >
        {({onSectionRendered, scrollToColumn, scrollToRow}) => (
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              <Fragment>
                <MultiGrid
                  ref={ref => (this._grid = ref)}
                  style={{
                    boxShadow: isBoxShadow
                      ? "0 1px 4px 0 rgba(41, 51, 57, 0.5)"
                      : "",
                    ...style
                  }}
                  cellRenderer={this.cellRenderer}
                  columnWidth={({ index }) => colDef[index].size}
                  columnCount={colDef.length}
                  fixedRowCount={fixedRowCount}
                  fixedColumnCount={fixedColumnCount}
                  rowHeight={row => (row.index === 0 ? this.getHeaderHeight() : rowHeight)}
                  height={fixedHeight || this.calculateGridHeight(list, rowHeight)}
                  width={fixedWidth || this.calculateGridWidth(colDef, width)}
                  rowCount={list.length + 1}
                  classNameTopLeftGrid="c-table--top-left-grid"
                  classNameTopRightGrid="c-table--top-right-grid"
                  classNameBottomLeftGrid="c-table--bottom-left-grid"
                  classNameBottomRightGrid="c-table--bottom-right-grid"
                  scrollToRow={scrollToRow}
                  scrollToColumn={scrollToColumn}
                  onSectionRendered={onSectionRendered}
                  // onScrollToChange={this._selectCell}
                />
                {footer &&
                  React.cloneElement(footer, {
                    width: fixedWidth || this.calculateGridWidth(colDef, width)
                  })}
              </Fragment>
            )}
          </AutoSizer>
        )}
      </TabKeyStepper>
    );
  }

  render() {
    const {
      colDef,
      fixedRowCount,
      fixedColumnCount,
      fixedHeight,
      fixedWidth,
      style,
      rowHeight,
      headerHeight,
      isBoxShadow,
      footer,
      list,
      showHeader,
    } = this.props;

    return (
      <AutoSizer disableHeight={true}>
        {({ width }) => {
          return (
          <Fragment>
            <MultiGrid
              ref={ref => (this._grid = ref)}
              style={{
                boxShadow: isBoxShadow
                  ? "0 1px 4px 0 rgba(41, 51, 57, 0.5)"
                  : "",
                ...style
              }}
              cellRenderer={this.cellRenderer}
              columnWidth={({ index }) => colDef[index].size}
              columnCount={colDef.length}
              fixedRowCount={fixedRowCount}
              fixedColumnCount={fixedColumnCount}
              rowHeight={row => (row.index === 0 ? this.getHeaderHeight() : rowHeight)}
              height={fixedHeight || this.calculateGridHeight(list, rowHeight)}
              width={fixedWidth || this.calculateGridWidth(colDef, width)}
              rowCount={list.length + 1}
              classNameTopLeftGrid="c-table--top-left-grid"
              classNameTopRightGrid="c-table--top-right-grid"
              classNameBottomLeftGrid="c-table--bottom-left-grid"
              classNameBottomRightGrid="c-table--bottom-right-grid"
              scrollToRow={this.state.scrollToRow}
              scrollToColumn={this.state.scrollToColumn}
              onScrollToChange={this._selectCell}
            />
            {footer &&
              React.cloneElement(footer, {
                width: fixedWidth || this.calculateGridWidth(colDef, width)
              })}
          </Fragment>
        );}}
      </AutoSizer>
    );
  }
}

Table.propTypes = {
  list: PropTypes.array.isRequired,

  colDef: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      label: PropTypes.any,
      component: PropTypes.func
    })
  ).isRequired,

  style: PropTypes.object,

  fixedRowCount: PropTypes.number,
  fixedColumnCount: PropTypes.number,
  fixedHeight: PropTypes.number,
  fixedWidth: PropTypes.number,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  fixedColLabel: PropTypes.any,

  footer: PropTypes.element,

  isBoxShadow: PropTypes.bool,
  isSortOn: PropTypes.bool,
  isZebra: PropTypes.bool,
  hoverOnX: PropTypes.bool,
  hoverOnY: PropTypes.bool,
  isEditable: PropTypes.bool,
  showHeader: PropTypes.bool,

  onLabelClick: PropTypes.func,
  onCellChange: PropTypes.func,
};

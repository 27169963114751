import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    createAddDecoration,
    createDeleteDecoration,
    createCopyDecoration,
    createDecoration,
    createUpdateDecorationRequest,
    createUpdateMatrix,
} from '../../actions/admin_decoration_charges';

import AddAdminDecoration from "./AddAdminDecoration";
import AdminDecorationChargesMatrix from "./AdminDecorationChargesMatrix";

export class Card extends Component {
    constructor(props) {
        super(props);

        this.state = {
            removing: false,
            charge_types: [
                {key: 'run', value: 'Run charge'},
                {key: 'fixed', value: 'Fixed Charge'},
            ],
        };
        this.onDelete = this.onDelete.bind(this);
        this.onCopy = this.onCopy.bind(this);
    }

    onDelete(decoration_id) {
        if (window.confirm("Are you sure you want to delete?")) {
            this.setState({removing: true,}, () => {
                setTimeout(() => {
                    this.props.deleteDecoration(decoration_id);
                    this.setState({removing: false,});
                }, 300);
            });
        }
    }

    onCopy(decoration_id) {
        this.props.copyDecoration(decoration_id);
        window.scrollTo(0, document.body.scrollHeight);
    }

    render() {
        const {
            decoration,
            decorator_id,
            idx,
            decoration_id,
            cols_num,
            rows_num,
            onSave,
            deleteDecoration,
            copyDecoration,
            can_edit,
        } = this.props;

        const { removing } = this.state;

        return (
            <div key={'_' + idx} className="medium-12 small-12 columns"
                style={{transition: 'all 250ms linear', opacity: removing ? 0 : 'unset', background: 'rgb(235, 240, 243)'}}
            >
                <div className="large-12 medium-12 columns card"
                    style={{
                        marginBottom: '20px',
                        textAlign: 'center',
                        fontSize: '2em',
                        padding: '10px 10px',
                        paddingBottom: '30px',
                    }}
                >
                    <div className="medium-12 columns">
                        <h4>
                            {decoration ? decoration.charge_name + '  ' : null}
                            {!can_edit ?
                                <span>{
                                    decoration && decoration.charge_type ?
                                        '(' + this.state.charge_types.filter(
                                            v => v.key==decoration.charge_type
                                        )[0].value + ')' : null
                                }</span>
                            : null}
                        </h4>
                        {can_edit ?
                            <div className="button-group" style={{float: 'right',}}>
                                <a className="button secondary-button" style={{marginRight: '10px', borderRight: 'none'}}
                                    onClick={this.onDelete}>Delete</a>
                                <a className="button secondary-button" style={{marginRight: '10px', borderRight: 'none'}}
                                    onClick={this.onCopy}>Copy</a>
                            </div>
                        : null}
                    </div>

                    {decoration.error ?
                        <div className="medium-12 columns">
                            <p style={{textAlign: 'center', fontSize: '0.8em', fontWeight: '600', color: 'red'}}>{decoration.error}</p>
                        </div>
                    : null}

                    <AddAdminDecoration
                        decoration_id={decoration_id}
                        decorator_id={decorator_id}
                        decoration_data={decoration}
                        cols_num={cols_num}
                        rows_num={rows_num}
                        edit_me={decoration.new ? true : false}
                        type={decoration.matrix.type}
                        can_edit={can_edit}
                    />
                    <br />
                    {!decoration.is_new || (decoration.charge_type && decoration.charge_name) ?
                        <AdminDecorationChargesMatrix
                            decoration_id={decoration_id}
                            decorator_id={decorator_id}
                            decoration_data={decoration}
                            type={decoration.matrix.type}
                            can_edit={can_edit}
                        />
                    : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = (dispatch, ownProps) => ({
    createDecoration: (data) => {
        const decoration_id = 'dec_temp_' + ownProps.decorations.length + '-' + parseInt(Math.random()*1000);
        return dispatch(createDecoration(ownProps.decorator_id, {decoration_id, ...data}));
    },
    addDecoration: (decorator_id, charge_name, charge_type, charge_desc, units) => dispatch(createAddDecoration(decorator_id, charge_name, charge_type, charge_desc, units)),
    deleteDecoration: () => dispatch(createDeleteDecoration(ownProps.decoration_id)),
    copyDecoration: () => dispatch(createCopyDecoration(ownProps.decoration_id)),
    onSave: (decoration) => {
        if (decoration.is_new) {
            return dispatch(createAddDecoration(decoration));
        }
        return dispatch(createUpdateDecorationRequest(decoration));
    },
    updateMatrix: (value) => dispatch(createUpdateMatrix(ownProps.decoration_id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Card);

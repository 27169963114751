import React from 'react';
import {polyfill} from 'react-lifecycles-compat';

export class TabKeyStepper extends React.PureComponent {
  static defaultProps = {
    disabled: false,
    isControlled: false,
    mode: 'edges',
    scrollToColumn: 0,
    scrollToRow: 0,
  };

  state = {
    scrollToColumn: 0,
    scrollToRow: 0,
    instanceProps: {
      prevScrollToColumn: 0,
      prevScrollToRow: 0,
    },
  };

  _columnStartIndex = 0;
  _columnStopIndex = 0;
  _rowStartIndex = 0;
  _rowStopIndex = 0;

  static getDerivedStateFromProps(
    nextProps,
    prevState,
  ) {
    if (nextProps.isControlled) {
      return {};
    }

    if (
      nextProps.scrollToColumn !== prevState.instanceProps.prevScrollToColumn ||
      nextProps.scrollToRow !== prevState.instanceProps.prevScrollToRow
    ) {
      return {
        ...prevState,
        scrollToColumn: nextProps.scrollToColumn,
        scrollToRow: nextProps.scrollToRow,
        instanceProps: {
          prevScrollToColumn: nextProps.scrollToColumn,
          prevScrollToRow: nextProps.scrollToRow,
        },
      };
    }

    return {};
  }

  setScrollIndexes({scrollToColumn, scrollToRow}) {
    this.setState({
      scrollToRow,
      scrollToColumn,
    });
  }

  render() {
    const {className, children} = this.props;
    const {scrollToColumn, scrollToRow} = this._getScrollState();

    return (
      <div className={className} onKeyDown={this._onKeyDown}>
        {children({
          onSectionRendered: this._onSectionRendered,
          scrollToColumn,
          scrollToRow,
        })}
      </div>
    );
  }

  _onKeyDown = (event) => {
    const {columnCount, disabled, mode, rowCount} = this.props;

    if (disabled) {
      return;
    }

    const {
      scrollToColumn: scrollToColumnPrevious,
      scrollToRow: scrollToRowPrevious,
    } = this._getScrollState();

    let {scrollToColumn, scrollToRow} = this._getScrollState();

    // The above cases all prevent default event event behavior.
    // This is to keep the grid from scrolling after the snap-to update.
    if (event.shiftKey && event.key == 'Enter') { // Up
        scrollToRow =
          mode === 'cells'
            ? Math.max(scrollToRow - 1, 0)
            : Math.max(this._rowStartIndex - 1, 0);
    } else if (event.key == 'Enter') { // Down
        scrollToRow =
          mode === 'cells'
            ? Math.min(scrollToRow + 1, rowCount - 1)
            : Math.min(this._rowStopIndex + 1, rowCount - 1);
    } else if (event.shiftKey && event.key == 'Tab') { // Left
        scrollToColumn =
          mode === 'cells'
            ? Math.max(scrollToColumn - 1, 0)
            : Math.max(this._columnStartIndex - 1, 0);
    } else if (event.key == 'Tab') { // Right
      console.log('right');
        scrollToColumn =
          mode === 'cells'
            ? Math.min(scrollToColumn + 1, columnCount - 1)
            : Math.min(this._columnStopIndex + 1, columnCount - 1);
    }

    // switch (event.key) {
    //     case 'ArrowDown':
    //       scrollToRow =
    //         mode === 'cells'
    //           ? Math.min(scrollToRow + 1, rowCount - 1)
    //           : Math.min(this._rowStopIndex + 1, rowCount - 1);
    //       break;
    //     case 'ArrowLeft':
    //       scrollToColumn =
    //         mode === 'cells'
    //           ? Math.max(scrollToColumn - 1, 0)
    //           : Math.max(this._columnStartIndex - 1, 0);
    //       break;
    //     case 'ArrowRight':
    //       scrollToColumn =
    //         mode === 'cells'
    //           ? Math.min(scrollToColumn + 1, columnCount - 1)
    //           : Math.min(this._columnStopIndex + 1, columnCount - 1);
    //       break;
    //     case 'ArrowUp':
    //       scrollToRow =
    //         mode === 'cells'
    //           ? Math.max(scrollToRow - 1, 0)
    //           : Math.max(this._rowStartIndex - 1, 0);
    //       break;
    // }

    if (
      scrollToColumn !== scrollToColumnPrevious ||
      scrollToRow !== scrollToRowPrevious
    ) {
      event.preventDefault();

      this._updateScrollState({scrollToColumn, scrollToRow});
    }
  };

  _onSectionRendered = ({
    columnStartIndex,
    columnStopIndex,
    rowStartIndex,
    rowStopIndex,
  }) => {
    this._columnStartIndex = columnStartIndex;
    this._columnStopIndex = columnStopIndex;
    this._rowStartIndex = rowStartIndex;
    this._rowStopIndex = rowStopIndex;
  };

  _getScrollState() {
    return this.props.isControlled ? this.props : this.state;
  }

  _updateScrollState({scrollToColumn, scrollToRow}) {
    const {isControlled, onScrollToChange} = this.props;

    if (typeof onScrollToChange === 'function') {
      onScrollToChange({scrollToColumn, scrollToRow});
    }

    if (!isControlled) {
      this.setState({scrollToColumn, scrollToRow});
    }
  }
}

polyfill(TabKeyStepper);

export default TabKeyStepper;

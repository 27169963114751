import React, { Component } from "react";

import { countDecimals } from '../utils';
import { convertToFloat } from '../../../admin-dec-charges-utils';

export class EditableCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: convertToFloat(this.props.children),
    };
  }

  onBlur = () => {
    this.props.onCellBlur(convertToFloat(this.state.inputValue));
  };

  onChangeInputValue = event => {
    this.setState({ inputValue: event.target.value });
  };

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      this.setState({ inputValue: this.props.children });
    }
  }

  render() {
    const {
      cellValue,
      onCellBlur,
      className,
      style,
      inputClass,
      disabled,
      isInputEditable,
      rowIndex,
      columnIndex,
      rowCount,
      columnCount,
      isNumberMatrix,
      minNum = isNumberMatrix ? {min: '0'} : null,
      scrollToColumn,
      scrollToRow,
      isLastCol,
      ...props
    } = this.props;

    return (
      <div className={className} style={style} {...props}>
        <input
          id={this.props.decoration_id + '_cell_' + rowIndex + '_' + columnIndex}
          type={isNumberMatrix ? 'number' : 'text'}
          {...minNum}
          style={{
            border: "0.5px solid #CCD5DA",
            borderWidth: "0px 1px 1px 0",
            height: "100%",
            width: "100%",
            borderRadius: 'unset',
            boxShadow: 'inset 0 0px 2px rgba(42, 56, 63, 0.1)',
            textAlign: 'left',
            backgroundColor: (isInputEditable ? 'initial' : 'white'),
            ...(rowIndex==1 ? {borderTop: 0} : {}), ...(columnIndex==1 ? {borderLeft: 0} : {})
          }}
          className={`c-editable-cell--input ${inputClass}`}
          value={this.state.inputValue}
          onChange={this.onChangeInputValue}
          onBlur={this.onBlur}
          disabled={disabled || !isInputEditable}
          onFocus={(e) => e.target.select()}
          onKeyDown={(e) => {
            if (e.shiftKey && e.key == 'Enter') {
              scrollToColumn(columnIndex);
              scrollToRow(rowIndex-1);

              const el = document.getElementById(this.props.decoration_id + '_cell_' + (rowIndex-1) + '_' + columnIndex);
              if (el) { el.focus(); }
            } else if (e.key == 'Enter') {
              scrollToColumn(columnIndex);
              scrollToRow(rowIndex+1);

              const el = document.getElementById(this.props.decoration_id + '_cell_' + (rowIndex+1) + '_' + columnIndex);
              if (el) { el.focus(); }
            } else if (e.shiftKey && e.key == 'Tab') {
              if (columnIndex !== 0) {
                scrollToColumn(columnIndex-1);
              } else {
                scrollToColumn(columnCount-1);
                const el = document.getElementById(this.props.decoration_id + '_cell_' + (rowIndex-1) + '_' + (columnCount-1));
                if (el) { el.focus(); }
              }
            } else if (e.key == 'Tab') {
              if (!isLastCol) {
                scrollToColumn(columnIndex+1);
              } else {
                scrollToColumn(1);
                scrollToRow(rowIndex+1);
                setTimeout(() => {
                  const el = document.getElementById(this.props.decoration_id + '_cell_' + (rowIndex+1) + '_' + 1);
                  if (el) { el.focus(); }
                }, 150);
              }
            }
          }}
        />
      </div>
    );
  }
}

import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { createAddSupplierPopup } from '../../actions/popup';
import {
    createLoadDivision,
    createLoadSupplier,
    createAddSupplier,
    createFetchSuppliers
} from '../../actions/supplier';
import {
    createLoadDecoratorList,
    createLoadDecorationsList,
    createAddDecoration,
    createLoadDecorationCharges,
    createDecoration,
    createAddDecoratorToState,
    createDeleteDecorator,
    createUpdateDecorator,
    createRemoveAllDecorations,
} from '../../actions/admin_decoration_charges';

import Select from '../Select';
import Card from './Card';
import TextArea from '../TextArea';
import { Button, EditIcon, IconButton } from '@commonsku/styles';

class AdminDecorations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            top_margin: null,
            // decorator_id: '',
            decorator_type: 'division',
            select_placeholder: "Select a decorator or type to find more",
            loading: this.props.loading,
            error: '',
            can_edit: false,
            decorator_description: '',
            sMatches: false,
        };

        [
            'onSelectDecorator',
            'onAddNewDecoration',
            'onClickSave',
            'filterDecorators',
            'onEditDone',
            'scrollToBottom',
            'onUpdateDecoratorDesc',
            'onDeleteAll',
        ].forEach(f => this[f] = this[f].bind(this));
    }

    componentDidMount() {
        this.props.loadDivisions('supplier-account', true);
        this.props.loadSuppliers();
        this.props.loadDecorators(this.state.decorator_type);
        this.scrollToTop();

        this.mediaQueriesHandle();
    }

    mediaQueriesHandle() {
        const sHandler = e => this.setState({ sMatches: e.matches });
        window.matchMedia('(max-width: 640px)').addListener(sHandler);
    }

    scrollToBottom() {
        this.root.scrollTo(0, this.root.scrollHeight + 300);
        window.scrollTo(0, document.body.scrollHeight + 300);
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.root.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
            block: "start",
        });
    }

    getDecorationData(decoration_id) {
        return this.props.decorations.data.filter(val => val.decoration_id == decoration_id)[0];
    }

    getDecoratorData(decorator_id) {
        const d = this.props.original_decorators.filter(val => val.decorator_id == decorator_id);
        return d && d.length > 0 ? d[0] : {};
    }

    onSelectDecorator(e) {
        const exists = this.getDecoratorData(e.value);
        if (Object.entries(exists).length > 0) {
            this.props.setDecorator(e.value, e.label);
            this.setState({ error: "", decorator_description: exists.description, can_edit: false });
        } else {
            this.props.setDecorator(e.value, e.label);
            this.setState({ error: "", decorator_description: this.getDecoratorData(e.value).description, can_edit: true });
        }
        this.scrollToTop();
    }

    onAddNewDecoration(e) {
        e.preventDefault();
        if (!this.props.decorator_id) {
            this.setState({ error: "Please select a decorator." });
            return;
        }

        this.props.createDecoration(this.props.decorator_id, {
            decoration_id: 'dec_temp_' + this.props.decorations.data.length + '-' + parseInt(Math.random() * 1000),
            qty: 1,
        });
        setTimeout(() => {
            this.scrollToBottom();
        }, 500);
    }

    onClickSave(decoration_id) {
        if (!decoration_id) {
            this.setState({ error: "Please select a decorator." });
            return;
        }
        const decoration_data = this.getDecorationData(decoration_id);
        this.props.addDecoration(decoration_id, decoration_data);
    }

    filterDecorators(filter, callback) {
        if (filter == "" || typeof (filter) != 'string') {
            callback(this.props.decorators.map(o => ({
                value: o.key, label: o.value, isDisabled: o.disabled
            })));
            return;
        }
        let all_decorators = this.props.divisions;
        if (this.state.decorator_type == 'supplier') {
            all_decorators = this.props.suppliers;
        }

        if (Array.isArray(all_decorators) && (all_decorators && all_decorators.length)) {
            callback(all_decorators.map(o => ({
                value: o.key, label: o.value, isDisabled: o.disabled
            })).filter(d => {
                let val = d.label ? d.label.toLowerCase() : '';
                return val.includes(filter.toLowerCase());
            }));
            return;
        }

        callback(this.props.decorators.map(o => ({
            value: o.key, label: o.value, isDisabled: o.disabled
        })));
        return;
    }

    onDeleteAll() {
        if (window.confirm("You are about to delete all matrices for this decorator, are you sure you want to do this?")) {
            this.props.deleteDecorator(this.props.decorator_id);
            this.props.deleteAllDecorations(this.props.decorator_id);
            this.props.setDecorator(null, null);
            this.setState({ can_edit: false });
        }
    }

    onEditDone() {
        this.setState(prevState => ({ can_edit: !prevState.can_edit }));
        this.props.onEditDone(this.props.decorator_id);
    }

    onUpdateDecoratorDesc = debounce((desc) => {
        this.setState({ decorator_description: desc });
        this.props.updateDecorator(this.props.decorator_id, { description: desc });
    }, 300)

    renderNoDecorations() {
        if (!this.props.decorator_id) { return; }
        return (
            <div className='large-12 medium-12 small-12 columns' style={{ marginTop: 20, fontSize: '1.5em', fontWeight: 600, color: '#3C505A', textAlign: 'center' }}>
                {/* No Decorations found. Click on Add New Decoration to start. */}
            </div>
        );
    }

    renderLoading() {
        return (
            <div className='row popup-content column decoration-matrix' style={{ marginTop: 30 }}>
                <div className='small-12 columns'>
                    <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
                </div>
            </div>
        );
    }

    editBtnCLass() {
        return this.state.can_edit ? 'cta-button' : 'btn';
    }

    render() {
        const style = this.props.style ? this.props.style : {};
        const inPopup = this.props.inPopup ? true : false;
        const originalStyle = {
            top: 0,
            left: 0,
            zIndex: 100,
            width: '100%',
            height: '100%',
            position: 'absolute',
            overflow: 'inherit',
            background: '#EBF0F3',
        };

        const overallStype = inPopup ? {} : { ...style, ...originalStyle, top: this.state.top_margin, marginTop: '-1rem', scrollBehavior: 'smooth', paddingBottom: 100, };
        const classNames = (inPopup ? '' : "main-content ") + "decoration-matrix";
        const { decorator_id, decorations, decorators, decorator_type } = this.props;
        const { error, select_placeholder, can_edit, decorator_description } = this.state;

        const decorator = decorator_id ? this.getDecoratorData(decorator_id) : {};

        if (decorator_type == 'supplier') {
            if (!this.props.suppliers.length || !(this.props.suppliers.length && this.props.suppliers.length > 1)) {
                return this.renderLoading();
            }
        } else if (decorator_type == 'division') {
            if (!this.props.divisions.length || !(this.props.divisions.length && this.props.divisions.length > 1)) {
                return this.renderLoading();
            }
        }

        return (
            <div ref={ref => this.root = ref} className={classNames} style={overallStype}>
                <div className="row full-width" style={{ marginTop: '2rem' }}>
                    <div className="large-12 medium-12 columns decorator-selection" style={{
                        marginBottom: 5,
                        position: inPopup ? 'relative' : 'fixed',
                        width: '100%',
                        backgroundColor: 'white',
                        marginTop: -15,
                        overflow: 'inherit',
                        zIndex: 10,
                        padding: 10,
                    }}>
                        <div className="small-12 medium-12 large-5 columns sel-decorator-p">
                            <Select
                                id="select_decorator"
                                label="Decorator"
                                value={decorator_id}
                                onChange={this.onSelectDecorator}
                                onOpen={(e) => {
                                    this.setState({
                                        select_placeholder: 'Type to search for more decorators',
                                    });
                                    const elem = document.querySelector(
                                        "#select_decorator .commonsku-styles-select__single-value"
                                    );
                                    if (!elem?.parentNode?.previousSibling?.firstChild) { return; }
                                    elem.parentNode.previousSibling.firstChild.innerHTML
                                        = '<b>Type to search for more decorators</b>';
                                }}
                                onBlur={(e) => {
                                    const elem = document.querySelector(
                                        "#select_decorator .commonsku-styles-select__single-value"
                                    );
                                    if (!elem?.parentNode?.previousSibling?.firstChild) { return; }
                                    if (decorator_id) {
                                        elem
                                            .parentNode.previousSibling.firstChild.innerHTML
                                            = this.getDecoratorData(decorator_id).decorator_name;
                                    }
                                }}
                                loadOptions={this.filterDecorators}
                                placeholder={select_placeholder}
                                options={decorators}
                                disabled={can_edit !== false && decorator_id}
                                async
                                defaultOptions
                            />
                        </div>
                        {decorator_id ?
                            <div>
                                {!this.state.sMatches ? <br /> : null}
                                {can_edit ?
                                    <IconButton size='small' onClick={this.onAddNewDecoration} id="add-btn" style={{ marginRight: '12px' }}
                                    >Add New Decoration</IconButton> : null}
                                <IconButton size='small'
                                    id="edit-done-btn"
                                    Icon={EditIcon}
                                    iconPosition="left"
                                    style={{
                                        padding: '7px',
                                        marginRight: '1rem',
                                    }}
                                    onClick={this.onEditDone}>{can_edit ? 'Save' : 'Edit'}</IconButton>
                                {!can_edit ? <IconButton preset='delete' size='small'
                                    id="edit-done-btn"
                                    onClick={this.onDeleteAll} />
                                    : null}
                            </div>
                            : null}
                    </div>
                    {decorator_id ?
                        <div className={"medium-12 small-12 columns" + (inPopup ? '' : " decorator-info-p")} style={inPopup ? {paddingTop: '12px', background: 'rgb(235, 240, 243)'} : null}>
                            <div className="large-12 medium-12 columns card"
                                style={{
                                    marginBottom: '40px',
                                    background: 'white',
                                    textAlign: 'left',
                                    padding: '10px 10px',
                                    paddingBottom: '30px',
                                }}
                            >
                                <div className="medium-12 columns">
                                    <h4>{decorator.decorator_name}</h4>
                                </div>
                                <div className="medium-12 columns">
                                    {can_edit ?
                                        <TextArea
                                            value={decorator_description}
                                            onChange={(e) => {
                                                // this.onUpdateDecoratorDesc(e.target.value);
                                                this.setState({ decorator_description: e.target.value });
                                                debounce((ev) => {
                                                    this.props.updateDecorator(this.props.decorator_id, { description: e.target.value });
                                                }, 300);
                                            }}
                                            onBlur={(e) => {
                                                this.props.updateDecorator(decorator_id, { description: e.target.value });
                                            }}
                                        />
                                        : (decorator_description ? decorator_description : '').split("\n").map((v, key) => {
                                            return <div key={key}>{v}</div>;
                                        })}
                                </div>

                            </div>

                        </div>
                        : null}

                    {error ?
                        <div className="medium-12 columns" style={{ fontSize: '1.3em', textAlign: 'center', fontWeight: 'bold' }}>
                            {error}
                        </div>
                        : null}

                    {(decorations.data && decorations.data.length) ?
                        <div>
                            {decorations.data.map((val, idx) => (
                                val && val.matrix &&
                                <Card
                                    key={'dec_card-' + idx}
                                    idx={idx}
                                    decoration_id={val.decoration_id}
                                    decorator_id={decorator_id}
                                    decoration={val}
                                    // matrix={val.matrix}
                                    cols_num={val.matrix.cols ? (val.matrix.cols.length == 0 ? 0 : val.matrix.cols.length - 1) : 0}
                                    rows_num={val.matrix.data.length}
                                    can_edit={can_edit}
                                />
                            ))}
                        </div>
                        : (decorations.loading ? this.renderLoading() : this.renderNoDecorations())}
                    <div className="large-12 medium-12 columns" style={inPopup ? { textAlign: 'left', paddingBottom: '12px', background: 'rgb(235, 240, 243)' } : {textAlign: 'left'}}>
                        {can_edit ?
                            <IconButton size='small' onClick={this.onAddNewDecoration}>Add New Decoration</IconButton>
                            : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let divisions = state.entities.divisions ? state.entities.divisions : state.dropdowns.divisions;
    divisions = divisions ? Object.values(divisions) : [];
    divisions = divisions.map(v => ({ key: v.division_id, value: v.division_name }));

    let suppliers = state.entities.suppliers ? state.entities.suppliers : state.dropdowns.suppliers;
    suppliers = suppliers ? Object.values(suppliers) : [];
    suppliers = suppliers.map(v => ({ key: v.supplier_id, value: v.supplier_name }));

    let decorators = state.dropdowns.decorators;
    decorators = decorators ? Object.values(decorators) : [];
    decorators = decorators.map(v => ({ key: v.decorator_id, value: v.decorator_name }));
    if (Array.isArray(decorators)) {
        decorators.push({
            value: <a href="#" onClick={(e) => {
                e.preventDefault();
                document.querySelector('#select_decorator .commonsku-styles-select__input')?.focus();
            }}>Start typing for more decorators</a>,
            key: '-',
            disabled: true,
        });
    }

    const original_decorators = state.dropdowns.decorators ? Object.values(state.dropdowns.decorators) : [];
    return {
        original_decorators,
        decorators: decorators,
        divisions: divisions,
        suppliers: suppliers,
        decorations: state.entities.decorations,
        decorator_id: state.entities.decorator_id,
        loading: state.entities.decorations.loading,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
    loadDivisions: (supplier_type, check_supplier_active = false) => {
        if ('collection' === supplier_type) {
            dispatch(createLoadSupplier(supplier_type));
        } else {
            dispatch(createLoadDivision('commonsku' === supplier_type ? 'supplier-direct' : supplier_type, check_supplier_active));
        }
    },
    loadSuppliers: supplier_type => dispatch(createLoadSupplier(supplier_type)),
    addSupplier: (supplier) => dispatch(createAddSupplier(supplier)),

    loadDecorators: (type) => dispatch(createLoadDecoratorList(type)),
    loadDecorations: (decorator_id, with_matrix = false) => dispatch(createLoadDecorationsList(decorator_id, with_matrix)),
    loadDecorationCharges: (decoration_id) => dispatch(createLoadDecorationCharges(decoration_id)), // decoration matrix data

    createDecoration: (decorator_id, data = {}) => {
        return dispatch(createDecoration(decorator_id, data));
    },
    addDecoration: (decorator_id, charge_name, charge_type, charge_desc, units) => {
        return dispatch(createAddDecoration(decorator_id, charge_name, charge_type, charge_desc, units));
    },

    onCreateFetchSuppliers: (keyword, tag_id, account_status_id, accounts_only) => {
        return dispatch(createFetchSuppliers(keyword, tag_id, account_status_id, accounts_only));
    },
    onCreateAddSupplierPopup: (redirect) => {
        return dispatch(createAddSupplierPopup(redirect));
    },

    addDecorator: (decorator_id, decorator_name) => dispatch(createAddDecoratorToState({ decorator_id, decorator_name })),
    deleteDecorator: (decorator_id) => dispatch(createDeleteDecorator(decorator_id)),
    setDecorator: (decorator_id, decorator_name) => {
        return dispatch(createAddDecoratorToState({ decorator_id, decorator_name }));
    },
    onEditDone: (decorator_id) => {

    },
    updateDecorator: (decorator_id, data) => dispatch(createUpdateDecorator(decorator_id, data)),
    deleteAllDecorations: (decorator_id) => {
        return dispatch(createRemoveAllDecorations(decorator_id));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDecorations);

import React from "react";
import { SortButton } from "../sortButton";
import cx from "classnames";

export const HeaderCell = ({
  isSortOn,
  headerLabel,
  isActiveSortCell,
  onSort,
  isAccendingSort,
  style,
  isBoxShadow,
  onLabelClick,
  columnIndex,
  ...props
}) => {
  const color = columnIndex == 0 ? 'white' : '#5CA3B6';

  return (
    <div
      style={{...style, textAlign: 'center', backgroundColor: '#f2f2f2', fontWeight: 'bold', color}}
      className={cx("c-table--th", {
        "c-table--box-shadow-th": isBoxShadow
      })}
    >
      <p
        onClick={onLabelClick ? onLabelClick : null}
        className={cx({
          "c-table--th-sort": isSortOn,
          "c-table--th-sort-active": isActiveSortCell,
          "c-table--th-label-clickable": onLabelClick
        })}
      >
        {headerLabel}
      </p>
      {isSortOn && (
        <SortButton
          onClick={onSort}
          isActive={isActiveSortCell}
          isAccending={isAccendingSort && isActiveSortCell}
        />
      )}
    </div>
  );
};
